import React, { useRef } from "react";
import "./Section8.css";
import WhyUs from "../../Cards/WhyUs/WhyUs";
import rightArrow from "../../../assets/gradientArrow.png";
import { Link } from "react-router-dom";
import useElementVisibility from "../../hooks/useElementVisibility";

const Section8 = (props) => {
  const buttonRef = useRef(null);

  const ServerData = props.ServerData;
  console.log(ServerData.BGColor);
  const isVisible = useElementVisibility(buttonRef);
  const renderData = ServerData.Cards.map((data, index) => {
    return <WhyUs Data={data} key={index} Order={index} />;
  });
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <section
      className="Section8"
      style={{ backgroundColor: ServerData.BGColor && ServerData.BGColor }}
    >
      <div className="Left">
        {ServerData.Tag && (
          <div
            className="Tag"
            style={{
              backgroundColor:
                getDataFromLocalStorage("TagBG") &&
                getDataFromLocalStorage("TagBG"),
            }}
          >
            <span>{ServerData.Tag}</span>
          </div>
        )}
        {ServerData.Title && <h2>{ServerData.Title}</h2>}
        {ServerData.Paragraph && (
          <p data-aos="fade-right">{ServerData.Paragraph}</p>
        )}
        <Link to={ServerData.ButtonLink}>
          <button
            className={`Button Empty  ${
              isVisible
                ? "animate__animated animate__slow  animate__fadeInLeft"
                : ""
            }`}
            ref={buttonRef}
          >
            <span>
              {ServerData.ButtonText}
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5L6.5 6.5L1.5 11.5"
                  stroke="url(#paint0_linear_264_60)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />

                <defs>
                  <linearGradient
                    id="paint0_linear_264_60"
                    x1="0.431452"
                    y1="6.61111"
                    x2="6.39919"
                    y2="6.61111"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      stopColor={getDataFromLocalStorage("gradientColor1")}
                    />
                    <stop
                      offset="1"
                      stopColor={getDataFromLocalStorage("gradientColor2")}
                    />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          </button>
        </Link>
      </div>
      <div className="Right">{renderData}</div>
    </section>
  );
};

export default Section8;
