import React, { useState, useEffect } from "react";
import "./Footer.css";
import {
  FaFacebook,
  FaYoutube,
  FaTelegram,
  FaPinterest,
  FaInstagram,
  FaWhatsapp,
  FaSkype,
  FaLinkedin,
} from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import Input from "../Input/Input";
import { SETDOC } from "../../server";
import { Link } from "react-router-dom";
const Footer = ({ ServerData, Tabs, Logo, FooterLogo }) => {
  const [socialContainer, setSocialContainer] = useState([]);
  const [newsletter, setNewsletter] = useState("");
  const objectToArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      key,
      ...obj[key],
    }));
  };

  useEffect(() => {
    const socialsArray = Object.keys(ServerData.Socials).map((key) => ({
      name: key,
      Link: ServerData.Socials[key],
    }));
    setSocialContainer(socialsArray);
  }, [ServerData.Socials]);

  const RenderSocials = socialContainer.map((Social) => {
    let icon;
    let link;

    switch (Social.name) {
      case "Facebook":
        icon = (
          <FaFacebook style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "Youtube":
        icon = (
          <FaYoutube style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "Twitter":
        icon = (
          <RiTwitterXFill
            style={{ fontSize: "1.5rem", color: "var(--icons)" }}
          />
        );
        link = Social.Link;
        break;
      case "Telegram":
        icon = (
          <FaTelegram style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "Pinterest":
        icon = (
          <FaPinterest style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "Instagram":
        icon = (
          <FaInstagram style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "WhatsApp":
        icon = (
          <FaWhatsapp style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = `https://wa.me/${Social.Link}`;
        break;
      case "Skype":
        icon = (
          <FaSkype style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      case "Linkedin":
        icon = (
          <FaLinkedin style={{ fontSize: "1.5rem", color: "var(--icons)" }} />
        );
        link = Social.Link;
        break;
      default:
        link = Social.Link;
        break;
    }

    return Social.Link ? (
      <li key={Social.name}>
        <Link style={{ textDecoration: "none" }} to={link}>
          {icon}
        </Link>
      </li>
    ) : null;
  });

  const renderLinks = ServerData.Links.map((link) => {
    if (link.FromPages) {
      const AttachedPage = objectToArray(Tabs).find((Tab) => {
        return Tab.id === link.PageID;
      });
      return (
        <li key={link.id}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/${AttachedPage.PageURL}`}
          >
            {AttachedPage.PageName}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={link.id}>
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            to={link.link}
          >
            {link.LinkLabel}
          </Link>
        </li>
      );
    }
  });
  const addToNewsLetter = () => {
    const updateNewsLetter = async () => {
      await SETDOC("NewsLetter", newsletter, { newsletter }, true);
      setNewsletter("");
    };
    updateNewsLetter();
  };
  return (
    <div
      className={`FooterWrapper ${
        ServerData.Gradient === "Second" ? "Second" : ""
      }`}
    >
      <div className="columns">
        <div className="col">
          <img src={FooterLogo ? FooterLogo : Logo} className="Logo" />
          {ServerData.belowLogoText && (
            <p className="belowLogoText">{ServerData.belowLogoText}</p>
          )}
        </div>
        <div className="col">
          <p>About</p>
          <ul>
            <li>
              <Link style={{ textDecoration: "none" }} to="/Blog">
                Blog
              </Link>
            </li>
            <li>
              <Link style={{ textDecoration: "none" }} to={Tabs.About.PageURL}>
                About
              </Link>
            </li>
          </ul>
        </div>
        <div className="col">
          <p>Quick Links</p>
          <ul>
            <>
              <li>
                <Link style={{ textDecoration: "none" }} to={`/Portal`}>
                  Portal
                </Link>
              </li>
              {renderLinks}
            </>
          </ul>
        </div>
        <div className="col NewsLetter">
          <p>Subscribe Newsletter</p>
          <span>{ServerData.newsLetterText}</span>
          <div className="newsLetterInput">
            <Input
              label="Enter Your Email"
              type="text"
              required={true}
              id="newsletter"
              name="newsletter"
              value={newsletter}
              onChangeFunction={(e) => {
                setNewsletter(e.target.value);
              }}
              customWidth="90%"
            />
            <button className="Button" onClick={addToNewsLetter}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="Links">
        <ul>{RenderSocials}</ul>
      </div>
      <div className="CopyRight">
        <p>{ServerData.BottomText}</p>
        <div>
          <Link style={{ textDecoration: "none" }} to={Tabs.TOU.PageURL}>
            Terms Of Use
          </Link>
          <span>&</span>
          <Link style={{ textDecoration: "none" }} to={Tabs.Privacy.PageURL}>
            Privacy Policy
          </Link>
        </div>
        {/* <p style={{ color: "white" }}>Website Version: 1.0 </p> */}

        <p style={{ color: "white" }}>Website Version: 1.1 </p>
      </div>
    </div>
  );
};

export default Footer;
