import React, { useState, useEffect, useRef } from "react";

import { CreateToast } from "../../../../App";
import { DELETEPHOTO, UPLOADPHOTO, UPLOADVIDEO } from "../../../../server";
import Upload from "../../../../assets/upload.png";
import VideoPlayer from "../../../VideoPlayer";
import TipTap from "./RichTextEditor/tiptap";
import Input from "../../../Input/Input";
import Select from "react-select";

const fontFamilyOptions = [
  { value: "", label: "Default" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Inter", label: "Inter" },
  { value: "Comic Sans MS", label: "Comic Sans MS" },
  { value: "serif", label: "serif" },
  { value: "Sans-Serif", label: "Sans-Serif" },
  { value: "monospace", label: "monospace" },
  { value: "Fantasy", label: "Fantasy" },
  { value: "Arial Black", label: "Arial Black" },
  { value: "Verdana", label: "Verdana" },
  { value: "Tahoma", label: "Tahoma" },
  { value: "Trebuchet MS", label: "Trebuchet MS" },
  { value: "Georgia", label: "Georgia" },
  { value: "Courier", label: "Courier" },
  { value: "Bradley Hand", label: "Bradley Hand" },
  { value: "Luminari", label: "Luminari" },
];
const Template7 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUploading, setVideoUploading] = useState(false);

  const DeleteVideo = async () => {
    if (videoUploading) {
      CreateToast("Video Uploading, please wait...", "error", 2000);
      return;
    }
    CreateToast("deleting video", "info");
    await DELETEPHOTO(`/customization/SidePages/${Data.id}/Video.mp4`);
    await UpdateData(BackEndName, { ...data, Video: "" });
    setData((prev) => ({ ...prev, Video: "" }));
    CreateToast("video deleted", "success");
  };
  const handleProgress = (progress) => {
    setUploadProgress(progress);
    if (progress === 100) {
      setVideoUploading(false);
    }
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "Video") {
      if (videoUploading) {
        CreateToast("uploading Video", "error", 2000);
        return;
      }
      setVideoUploading(true);
      CreateToast("uploading Video", "info", 10000);
      const file = e.target.files[0];
      const url = await UPLOADVIDEO(
        `/customization/SidePages/${Data.id}/Video.mp4`,
        file,
        handleProgress
      );

      setData((prev) => {
        return { ...prev, Video: url };
      });
      setUploadProgress(0);
      CreateToast("Video uploaded", "success", 2000);

      UpdateData(BackEndName, { ...data, Video: url });
      return;
    } else if (name === "Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, Icon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else if (name === "BG") {
      CreateToast("uploading background", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/BG.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, BG: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const handlePostBodyChange = (value, Target) => {
    let valueToChange;
    switch (Target) {
      case "HighlightsBody":
        valueToChange = "HighlightsBody";
        break;
      case "OverViewBody":
        valueToChange = "OverViewBody";
        break;
      case "Section3Body":
        valueToChange = "Section3Body";
        break;
      case "SupportBody":
        valueToChange = "SupportBody";
        break;
      default:
        break;
    }
    setData((prev) => {
      return { ...prev, [valueToChange]: value };
    });
  };
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);

  return (
    <div className="DataEntry Hosting">
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL:"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Product Description: "
          type="text"
          id="Description"
          name="Description"
          required={true}
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Header Title:"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Name in navigation :"
          type="text"
          id="PageName"
          name="PageName"
          value={data.PageName}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Bottom Title:"
            type="text"
            required={true}
            id="BottomTitle"
            name="BottomTitle"
            value={data.BottomTitle}
            onChangeFunction={handleInput}
            style={{ color: data.BottomColor }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label htmlFor="fontSelect">Page Font Family:</label>
          <Select
            options={fontFamilyOptions}
            value={fontFamilyOptions.find((font) => font.value === data.Font)}
            onChange={(selectedOption) =>
              setData((prev) => {
                return { ...prev, Font: selectedOption.value };
              })
            }
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>
        <div style={{ display: "flex", gap: "20px" }}>
          <div className="formItem">
            <span>Background: </span>
            <label htmlFor="BG">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>

            <input
              type="file"
              accept="image/*"
              hidden
              id="BG"
              name="BG"
              onChange={handleInput}
            />
          </div>
          <div className="formItem">
            <span>Icon:</span>
            <label htmlFor="Icon">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>

            <input
              type="file"
              accept="image/*"
              hidden
              id="Icon"
              name="Icon"
              onChange={handleInput}
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div className="UploadWrapper">
            <div className="FormItem">
              <span>Video: </span>
              <label htmlFor="Video">
                <img
                  src={Upload}
                  style={{ width: "25px", cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                accept="video/*"
                hidden
                id="Video"
                name="Video"
                onChange={handleInput}
              />
            </div>
          </div>
          {data.Video && (
            <button className="Button Danger" onClick={DeleteVideo}>
              Delete
            </button>
          )}
        </div>
        {uploadProgress != 0 && (
          <div className="video-progress-bar">
            <div
              className="video-progress-bar-fill"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        {data.Video && (
          <div style={{ width: "500px" }}>
            <VideoPlayer videoUrl={data.Video} />
          </div>
        )}
      </div>

      <div className="boxWrapper">
        <h4 className="BoxTitle">Body</h4>

        <TipTap
          editorClassName="First"
          setHTML={(value) => {
            handlePostBodyChange(value, "OverViewBody");
          }}
          OldData={data.OverViewBody}
        />
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            if (videoUploading) {
              CreateToast("Video Uploading, please wait...", "error", 2000);
              return;
            }
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template7;
