import React, { useState, useEffect } from "react";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import "./NavMobile.css";
import sortBy from "sort-by";
import { GETCOLLECTION } from "../../server";
import { Link } from "react-router-dom";
import ArrowDown from "../../assets/arrowDown.png";

const NavMobile = ({ Tabs, setActiveServicePage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [nonList, setNonList] = useState([]);
  const [dropDowns, setDropDowns] = useState([]);
  const [TabsAR, setTabsAR] = useState([]);

  const urlFormatted = window.location.href
    .split("/")
    .pop()
    .replace(/%20/g, " ");

  useEffect(() => {
    if (Tabs) {
      let ArPages = [];
      let TempNon = [];

      for (const key in Tabs) {
        if (Object.hasOwnProperty.call(Tabs, key)) {
          const element = Tabs[key];
          ArPages.push({
            Name: key,
            PageName: element.PageName,
            PageURL: element.PageURL,
            DropDownFamily: element.DropDownFamily,
            id: element.id,
          });
        }
      }
      setTabsAR(ArPages);
      TempNon = ArPages.filter((Page) => {
        return Page.DropDownFamily === "0";
      });
      setNonList(TempNon.sort(sortBy("id")));
    }
  }, [Tabs]);
  const toggleMenu = (isOpen) => {
    setIsMenuOpen(isOpen);
  };
  const RenderNonList = nonList?.map((link) => {
    if (
      link.id === "16" ||
      link.id === "17" ||
      link.id === "33" ||
      link.id === "34" ||
      link.id === "32"
    ) {
      return;
    }
    if (link.PageURL) {
      return (
        <li key={link.id}>
          <Link
            className={urlFormatted === link ? "ActiveLink" : ""}
            onClick={() => {
              setIsMenuOpen(false);
            }}
            to={`/${link.PageURL}`}
          >
            {link.PageName}
          </Link>
        </li>
      );
    }
  });
  useEffect(() => {
    const fetchData = async () => {
      setDropDowns(await GETCOLLECTION("DropDown"));
    };
    fetchData();
  }, []);
  const Render = dropDowns.map((DropDown) => {
    if (DropDown.id == "0") {
      return;
    }
    const TabsToRender = DropDown.Pages.reduce((acc, pageId) => {
      const page = TabsAR.find((item) => item.id === pageId);
      if (page && page.PageURL) {
        acc.push(page);
      }
      return acc;
    }, []);
    return (
      <li key={DropDown.id} className="dropdown">
        <a style={{ cursor: "pointer" }}>
          <span
            onClick={() => {
              if (DropDown?.Link) {
                window.location.href = DropDown.Link;
              }
            }}
          >
            {DropDown.Name}
          </span>
          {TabsToRender.length > 0 && <img src={ArrowDown} />}
        </a>
        {TabsToRender.length > 0 && (
          <ul>
            {TabsToRender.map((link) => (
              <li key={link.id} className="LinkBox">
                {link.Icon && (
                  <div className="left">
                    <img src={link.Icon} />
                  </div>
                )}
                <div className="Right">
                  <Link
                    className="PageName"
                    onClick={() => {
                      setActiveServicePage(link);

                      setIsMenuOpen((prev) => !prev);
                    }}
                    to={
                      DropDown.id === "1"
                        ? `${DropDown.Link}`
                        : `/${link.PageURL}`
                    }
                  >
                    {link.PageName}
                  </Link>
                  {link.Description && (
                    <span className="Description"> {link.Description}</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  });

  return (
    <>
      <HamburgerButton onToggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      <div className={`SideMenu ${isMenuOpen ? "Active" : ""}`}>
        <nav id="navbar" className="navbar">
          <ul>
            <li className={urlFormatted === "" ? "ActiveLink" : ""}>
              <Link style={{ textDecoration: "none" }} to="/">
                Home
              </Link>
            </li>

            {Render}
            {RenderNonList}
            <li className={urlFormatted === "Blog" ? "ActiveLink" : ""}>
              <Link style={{ textDecoration: "none" }} to="/Blog">
                Blog
              </Link>
            </li>

            <li>
              <button
                className="Button"
                onClick={() => {
                  window.location.href = `/${
                    TabsAR.find((page) => page.id === "34").PageURL
                  }`;
                }}
              >
                Contact Us
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default NavMobile;
